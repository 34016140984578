import { sortBy } from 'instantsearch.js/es/widgets';

export const sortByPriceWidget = sortBy({
    container: document.querySelector('#sort-by-price'),
    items: [
        {label: 'Default', value: algoliaIndexName},
        {label: 'Highest Price', value: `${algoliaIndexName}_price_desc`},
        {label: 'Lowest Price', value: `${algoliaIndexName}_price_asc`},
        {label: 'Newest First', value: `${algoliaIndexName}_newest`},
        {label: 'Oldest First', value: `${algoliaIndexName}_oldest`},
    ],
    cssClasses: {
        select: 'form-select',
        item: 'form-check',
        itemLabel: 'form-check-label',
        itemInput: 'form-check-input',
    },
});
