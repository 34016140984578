import {connectRange} from "instantsearch.js/es/connectors";

const renderRangeInput = (renderOptions, isFirstRender) => {
    const {start, range, refine, widgetParams} = renderOptions;
    const [min, max] = start;

    if (isFirstRender) {
        const form = document.createElement("form");
        form.classList.add("flex", "flex-column", "g-2", "mb-3");

        form.addEventListener("submit", (event) => {
            event.preventDefault();

            const rawMinInputValue = parseFloat(event.target.elements.min.value.replace(/,/g, ''));
            const rawMaxInputValue = parseFloat(event.target.elements.max.value.replace(/,/g, ''));

            refine([
                Number.isFinite(rawMinInputValue) ? rawMinInputValue : undefined,
                Number.isFinite(rawMaxInputValue) ? rawMaxInputValue : undefined,
            ]);
        });

        widgetParams.container.appendChild(form);

        return;
    }

    const formatNumber = (num) => {
        return Number.isFinite(num) ? num.toLocaleString('en-US') : '';
    };

    widgetParams.container.querySelector("form").innerHTML = `
        <div class="col">
            <div class="input-group">
            <span class="input-group-text">AED</span>
                <input id="range-price-min" name="min" type="text" class="form-control" 
                    placeholder="${formatNumber(range.min)}" 
                    value="${formatNumber(min)}">
            </div>
        </div>
        <div class="col-auto subheader" style="margin-top: 0.5rem; margin-bottom: 0.5rem;">to</div>
        <div class="col">
            <div class="input-group">
            <span class="input-group-text">AED</span>
                <input id="range-price-max" name="max" type="text" class="form-control" 
                    placeholder="${formatNumber(range.max)}" 
                    value="${formatNumber(max)}">
            </div>
        </div>
        <input type="submit" class="btn btn-outline-primary w-100" style="width: 10px; " hidden/>
    `;

    const inputs = widgetParams.container.querySelectorAll('input[type="text"]');
    inputs.forEach(input => {
        input.addEventListener('input', (e) => {
            let value = e.target.value.replace(/[^\d,]/g, '');
            value = value.replace(/,/g, '');
            if (value) {
                e.target.value = parseInt(value).toLocaleString('en-US');
            }
        });
    });
};

const customRangeInput = connectRange(renderRangeInput);

export const priceRangeInputWidget = customRangeInput({
    container: document.querySelector("#range-input-price"),
    attribute: "price",
});
